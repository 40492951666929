.ant-btn-primary {
  background-color: #58d11b !important;
  border-radius: 10;
  align-items: center;
  padding: 20;
}

.ant-btn-danger {
  background-color: #d11b1b !important;
  border-radius: 10;
  align-items: center;
  padding: 20;
  color: white;
}

.ant-pagination-item-active {
  border-color: #58d11b !important ;
  border-radius: 10;
  align-items: center;
  padding: 20;
}
li.ant-pagination-item-active a {
  color: #58d11b !important;
}
.ant-menu {
  background-color: black;
}
.ant-menu span {
  color: white;
}
.ant-menu-item-selected {
  background-color: #58d11b !important;
}
.ant-menu-item-selected span {
  color: white !important;
}
.ant-menu-item-active {
  color: #58d11b;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
